import {
  validateExtensions,
} from '../utils/validateFiles'

const extensions = ['.doc', '.docx', '.pdf', '.ppt', '.xls', '.xlsx', '.jpg', '.png', '.jpeg', '.zip']
const filesDrop = $('.js-uploadFileZone')
const uploadFileList = $('.js-uploadFileList')
const uploadFileInput = $('.js-uploadFileInput')

if (typeof (window.FileReader) === 'undefined') {
  console.log('Не поддерживается браузером!')
  filesDrop.addClass('is_error_filereader')
}

filesDrop.on('dragover', (event) => {
  event.preventDefault()
  event.stopPropagation()
  filesDrop.addClass('is_hover')
})

filesDrop.on('dragleave', (event) => {
  event.preventDefault()
  event.stopPropagation()
  filesDrop.removeClass('is_hover')
})

filesDrop.on('drop', (event) => {
  const file = event.originalEvent.dataTransfer.files
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  event.preventDefault()
  event.stopPropagation()

  filesDrop.removeClass('is_hover')
  filesDrop.addClass('is_drop')
  uploadFileInput.prop('files', file)

  if (!isSafari) {
    uploadFileInput.trigger('change')
  }

  return false
})

uploadFileInput.on('change', (event) => {
  const { files } = event.target

  $.each(files, (i, file) => {
    if (validateExtensions(file, extensions)) {
      generateTemplate(file)
    }
  })
})

$(document).on('click', '.js-fileDelete', (event) => {
  const current = $(event.currentTarget)

  current.parent().remove()
})

const getFileName = (name) => {
  if (name.indexOf('.') >= 0) {
    const arr = name.split('.')
    arr.pop()
    return arr.join('.')
  }
  return name
}

$(document).on('click', '.js-fileEdit', (event) => {
  const current = $(event.currentTarget)
  const parent = current.parent()
  const input = parent.find('.upload-line__title input')
  const val = input.val()
  const ext = input.data('ext')

  parent.toggleClass('is_edit_mode')

  if (parent.hasClass('is_edit_mode')) {
    const fileName = getFileName(val)
    current.text('сохранить')
    input
      .val(fileName)
      .prop('readonly', false)
      .focus()
  } else {
    current.text('изменить')
    input
      .val(`${val}.${ext}`)
      .prop('readonly', true)
  }
})

function generateTemplate(file) {
  const reader = new FileReader()
  const ext = file.name.split('.')

  reader.onload = function () {
    const dataURL = reader.result

    const tpl = `
      <div class="uploadzone__elem">
        <div class="upload-line">
          <input type="file" name="files[]" value="${dataURL}" />
          <div class="upload-line__title">
            <input type="text" readonly data-ext="${ext[ext.length - 1]}" value="${file.name}" />
          </div>
          <div class="upload-line__edit js-fileEdit">изменить</div>
          <div class="upload-line__close js-fileDelete">
            <svg class="icon icon-t-close ">
              <use xlink:href="images/sprites.svg#t-close"></use>
            </svg>
          </div>
        </div>
      </div>
    `

    uploadFileList.append(tpl)
  }
  reader.readAsDataURL(file)
}
