import 'magnific-popup'

$.extend(true, $.magnificPopup.defaults, {
  tClose: 'Закрыть (Esc)',
  tLoading: 'Загружается...',
  gallery: {
    tPrev: 'Предыдущая (←)',
    tNext: 'Следующая (→)',
    tCounter: '%curr% из %total%',
  },
  image: {
    tError: '<a href="%url%">Данная картинка</a> не может быть загружена.',
  },
  ajax: {
    tError: '<a href="%url%">Содержимое</a> не может быть загружено.',
  },
  closeMarkup: '<div class="mfp-close js-mfp-close"></div>',
  removalDelay: 300,
  closeBtnInside: true,
  fixedContentPos: false,
  autoFocusLast: false,
})

function modalOpen(src, type) {
  $.magnificPopup.open({
    items: {
      src,
      type,
    },
    closeBtnInside: false,
    callbacks: {
      open() {
        $('html').addClass('mfp-open')
        // disableBodyScroll(true, ".mfp-wrap")
      },
      close() {
        $('html').removeClass('mfp-open')
        // disableBodyScroll(false)
      },
    },
  })
}

$('.js-modalImage').magnificPopup({
  type: 'image',
  closeBtnInside: false,
  gallery: {
    enabled: true,
  },
})

$(document).on('click', '.js-modalClose', () => {
  $.magnificPopup.close()
})

$(document).on('click', '.js-modalLink', (event) => {
  const $this = $(event.currentTarget)
  const src = $this.data('mfp-src')
  const type = $this.data('mfp-ajax') || 'inline'

  event.preventDefault()

  modalOpen(src, type)
})

window.modalOpen = function (modal) {
  modalOpen(modal)
}

window.modalClose = function () {
  $.magnificPopup.close()
}
