const $body = $('body')
const $menuServices = $('.js-menuServices')
const $menuServicesContainer = $('.js-menuServicesContainer')
let timerId = null

$('.js-asideServicesLink')
  .on('mouseenter', () => {
    $body.addClass('is-menu-services-open')
    $menuServices.addClass('is-open')
  })
  .on('mouseleave', () => {
    timerId = setTimeout(() => {
      $body.removeClass('is-menu-services-open')
      $menuServices.removeClass('is-open')
    }, 300)
  })

$menuServices
  .on('mouseenter', () => {
    clearTimeout(timerId)
  })
  .on('mouseleave click', () => {
    $body.removeClass('is-menu-services-open')
    $menuServices.removeClass('is-open')
  })

$menuServicesContainer
  .on('click', (e) => e.stopPropagation())

$('.js-menuServicesLink')
  .on('click', (e) => {
    const id = $(e.currentTarget).data('id')
    const subnav = $(`[data-subnav-id="${id}"]`)

    e.preventDefault()

    subnav.addClass('is-open')
  })

$('.js-menuServicesReturn')
  .on('click', () => {
    $('[data-subnav-id]').removeClass('is-open')
  })
