import Swiper, { Autoplay, Pagination, Navigation } from "swiper";

Swiper.use([Autoplay, Pagination, Navigation]);

let canChange = true;

function heroSliderInit() {
  const heroSwiper = new Swiper(".js-heroSlider .swiper-container", {
    speed: 0,
    init: false,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".js-heroSliderNext",
      prevEl: ".js-heroSliderPrev",
    },
    noSwipingClass: "swiper-slide",
    pagination: {
      el: ".js-heroSlider .swiper-pagination",
      type: "bullets",
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}">
          <svg><circle cx="15" cy="15" r="5"></circle></svg>
        </span>`;
      },
    },
    on: {
      init() {
        const { realIndex, previousIndex } = this;

        changeSlide(realIndex, previousIndex);
      },
      slideChange() {
        const { realIndex, previousIndex } = this;

        changeSlide(realIndex, previousIndex);
        setTimeout(() => {
          canChange = true;
        }, 2000);
      },
    },
  });

  heroSwiper.init();

  function changeSlide(index, prevIndex) {
    const prev = $(".js-heroSlider .swiper-slide").eq(prevIndex);
    const slide = $(".js-heroSlider .swiper-slide").eq(index);

    prev.addClass("hero-slide-prev");

    setTimeout(() => {
      slide
        .addClass("hero-slide-active")
        .siblings()
        .removeClass("hero-slide-active hero-slide-prev");
    }, 1000);
  }

  $("body").on("DOMMouseScroll mousewheel", (e) => {
    if (canChange) {
      canChange = false;
      if (e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0) {
        if (heroSwiper.isEnd) {
          heroSwiper.slideTo(0);
        } else {
          heroSwiper.slideNext();
        }
      } else {
        heroSwiper.slidePrev();
      }
    }
  });

  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  var xDown = null;
  var yDown = null;

  function getTouches(evt) {
    return (
      evt.touches ||
      evt.originalEvent.touches
    ); 
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        heroSwiper.slideNext();
      } else {
        heroSwiper.slidePrev();
      }
    }

    xDown = null;
    yDown = null;
  }
}

if ($(".js-heroSlider").length > 0) {
  heroSliderInit();
}
