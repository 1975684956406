const $progressPath = $('.js-scrollProgressPath')

$(window).on('scroll load', () => {
  const ww = $(window).width()
  const isTablet = ww <= 1280

  if ($progressPath.length > 0) {
    const value = isTablet ? 440 : 600
    const scrolled = $(window).scrollTop()
    const documentHeight = $(document).height() - $(window).height()
    const percent = (scrolled * 100) / documentHeight
    const dashoffset = (percent * value) / 100

    $progressPath.css('stroke-dashoffset', `${value + dashoffset}`)
  }
})
