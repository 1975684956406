const attachFileList = $('.js-attachFileList')
const attachButton = $('.js-attachButton')

$(document).on('change', '.js-attachFileInput', (e) => {
  const current = $(e.currentTarget)
  const file = e.target.files[0]

  if (current.val()) {
    attachFileList.append(`
      <div class="file-line">
        <div class="file-line__title">${file.name}</div>
        <div class="file-line__del js-fileLineDel">
          <svg class="icon icon-t-close ">
            <use xlink:href="images/sprites.svg#t-close"></use>
          </svg>
        </div>
      </div>
    `)

    attachFileList.find('.file-line:last').append(current)

    attachButton.prepend('<input class="js-attachFileInput" type="file" name="files[]">')
  }
})

$(document).on('click', '.js-fileLineDel', (e) => {
  $(e.currentTarget).parents('.file-line').remove()
})
