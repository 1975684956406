import Swiper, {
  Scrollbar, Pagination, Navigation,
} from 'swiper'

Swiper.use([Pagination, Navigation, Scrollbar])

$('.js-teamSlider').each((index, el) => {
  const container = $(el).find('.swiper-container')
  const slides = $(el).find('.swiper-slide')
  const prev = $(el).find('.js-teamSliderPrev')
  const next = $(el).find('.js-teamSliderNext')
  const scrollbar = $(el).find('.swiper-scrollbar')
  const initialSlide = $(el).data('initialslide')

  new Swiper(container[0], {
    speed: 1000,
    initialSlide,
    centeredSlides: $(window).width() >= 768 && slides.length < 6,
    observer: true,
    observeParents: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: next[0],
      prevEl: prev[0],
    },
    scrollbar: {
      el: scrollbar[0],
      draggable: true,
    },
  })
})

$('.js-teamViewControl').on('click', (e) => {
  const type = $(e.currentTarget).data('view')
  const section = $(e.currentTarget).parents('.section-box')
  const slider = section.find('.section-box__team-slider')
  const list = section.find('.section-box__team-list')

  $(e.currentTarget)
    .addClass('is_active')
    .siblings()
    .removeClass('is_active')

  switch (type) {
  case 'tile':
    list.show()
    slider.hide()
    break

  default:
    list.hide()
    slider.show()
    break
  }
})
