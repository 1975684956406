const $body = $('body')

$('.js-menuBurger').on('click', () => {
  $body.toggleClass('is-menu-open')
})

$('.js-menuNavToggle').on('click', (e) => {
  const currentTarget = $(e.currentTarget)
  const parent = currentTarget.parent()
  const subnav = parent.find('.menu-nav__sublist')

  e.preventDefault()

  parent.toggleClass('is-open')
  subnav.slideToggle(200)
})
