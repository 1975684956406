import getScrollBarWidth from '../utils/getScrollBarWidth'

export default class ModalService {
  constructor() {
    this.DOM = {}

    this.init()
  }

  init() {
    this.cacheSelectors()
    this.bindEvents()
  }

  cacheSelectors() {
    this.DOM.$body = $('body')
    this.DOM.$modal = $('.js-modalService')
  }

  bindEvents() {
    this.DOM.$body.on('click', (e) => {
      if (!$(e.target).closest('.modal-service-box').length && !$(e.target).is('.modal-service-box')) {
        this.modalClose()
      }
    })

    this.DOM.$body.on('click', '.js-modalServiceLink', (e) => this.modalOpen(e))
    this.DOM.$body.on('click', '.js-modalServiceClose', () => this.modalClose())
  }

  modalOpen(e) {
    const currentTarget = $(e.currentTarget)
    const modalId = currentTarget.data('modal-id')
    const modal = $(`${modalId}`)
    const scrollBarWidth = getScrollBarWidth()

    e.stopPropagation()

    modal.addClass('is_open')
    this.DOM.$body.addClass('is-modal-service-open')
    this.DOM.$body.css('margin-right', scrollBarWidth)

    setTimeout(() => {
      modal.addClass('is_visible')
    }, 33)
  }

  modalClose() {
    const self = this

    self.DOM.$modal.removeClass('is_visible')

    setTimeout(() => {
      self.DOM.$modal.removeClass('is_open')
      self.DOM.$body.removeClass('is-modal-service-open')
      self.DOM.$body.css('margin-right', '')
    }, 300)
  }
}
