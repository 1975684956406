$('.js-tabLink').on('click', (e) => {
  const id = $(e.currentTarget).attr('href')
  const content = $(`${id}`)

  e.preventDefault()

  $(e.currentTarget)
    .addClass('is_active')
    .siblings()
    .removeClass('is_active')

  content
    .show()
    .siblings()
    .hide()
})