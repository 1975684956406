import LogoAnimation from './LogoAnimation'

const $body = $('body')
const $preloader = $('.js-pagePreloader')

$(window).on('load', () => {
  preloaderHide()

  setTimeout(() => {
    $body.addClass('is-animate-show')
    new LogoAnimation()
  }, 500)
})

function preloaderHide() {
  $preloader.addClass('is_hide')

  setTimeout(() => { $preloader.remove() }, 2000)
}
