$(document).on('change', '.js-formDownloadInput', (event) => {
  const input = $(event.currentTarget)
  const parent = input.parent()
  const previewImage = parent.find('.document-upload__preview')
  const file = event.target.files[0]

  if (fileExtensions(file)) {
    parent.addClass('is_active')
    getBase64({
      file,
      previewImage,
    })
  } else {
    parent.removeClass('is_active')
    input.val('')
  }
})

$(document).on('click', '.js-formDownloadDel', (event) => {
  const _this = $(event.currentTarget)
  const parent = _this.parent()
  const input = parent.find('input')
  const previewImage = parent.find('.document-upload__preview')

  event.preventDefault()

  input.val('')
  parent.removeClass('is_active')
  previewImage.css('background-image', 'none')
})

const fileExtensions = (file) => {
  const { name } = file
  const extensions = ['.jpg', '.jpeg', '.png']
  let valid = false
  for (let j = 0; j < extensions.length; j += 1) {
    const extension = extensions[j]
    if (name.substr(name.length - extension.length, extension.length).toLowerCase() === extension.toLowerCase()) {
      valid = true
      break
    }
  }
  return valid
}

function getBase64({ file, previewImage }) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    previewImage.css('background-image', `url("${reader.result}")`)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}
