const { hash } = window.location
const scrollLink = $(`.js-scrollTo[href="${hash}"]`)

$('.js-scrollTo').on('click', (event) => {
  const self = $(event.currentTarget)
  const scrollId = self.attr('href').replace('#', '')
  const containerScroll = $(`[data-scroll-section=${scrollId}]`)
  const headerHeight = $('.header').outerHeight() || 0

  if (containerScroll.find('.js-toggle').length > 0) {
    const currentToggle = containerScroll.find('.js-toggle')

    allAllToggleBlocks()
    currentToggle.addClass('is_active')
    currentToggle.next().show()
  }

  const offset = ($(window).width() >= 768
    ? Number(containerScroll.data('offset'))
    : Number(containerScroll.data('m-offset'))
  ) || 30

  event.preventDefault()

  if (containerScroll.length === 0) return

  const containerOffset = containerScroll.offset().top
  const scrollOffset = containerOffset - headerHeight - offset

  $('body, html').animate({ scrollTop: scrollOffset })
})

$(window).on('load', () => {
  if (scrollLink.length > 0) {
    scrollLink.trigger('click')
  }
})

function allAllToggleBlocks() {
  $('.js-toggle').removeClass('is_active')
  $('.js-toggle').next().hide()
}
