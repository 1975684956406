const mapContainer = $('#contactMap');
const mapPinIcon = mapContainer.data('pin');
const body = $('body');
let contactMap;

function mapInit() {
  let mapCenter = mapContainer.data('center').split(',');
  mapCenter = [mapCenter[0], mapCenter[1]];

  contactMap = new ymaps.Map(
    mapContainer[0],
    {
      center: mapCenter,
      zoom: 15,
      controls: [],
    },
    { suppressMapOpenBlock: true }
  );

  contactMap.behaviors.disable('scrollZoom');
  $(window).width() <= 767 && contactMap.behaviors.disable('drag');

  function moveMap () {
    if ($(window).width() >= 1280) {
      const newcoord = contactMap.getGlobalPixelCenter();
      contactMap.setGlobalPixelCenter([newcoord[0] -= 420, newcoord[1]]);
    }
  }

  function addMarker (coords) {
    let marker = new ymaps.Placemark(coords, {}, {
      iconLayout: 'default#image',
      iconImageHref: mapPinIcon,
      iconImageOffset: [-35, -62],
      iconImageSize: [67, 62],
    })
  
    contactMap.geoObjects.add(marker);

    contactMap.setCenter(coords)
  }

  addMarker(mapCenter)

  moveMap()

  $('.js-contactMapLink').on('click', (e) => {
    const coords = $(e.currentTarget).data('coords').split(',')

    contactMap.geoObjects.removeAll()
    addMarker([coords[0], coords[1]])
    moveMap()
  })
}

typeof ymaps !== 'undefined' &&
  $('#contactMap').length > 0 &&
  ymaps.ready(mapInit);
