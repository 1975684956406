import './preloader'
import './hero'
import './menu'
import './menu-services'
import './progress-scroll'
import './search'
import './form-download'
import './attach-files'
import './uploadzone'
import './form-extended'
import './toggle'
import './scrollTo'
import './modal'
import './team'
// import './about'
import './contact'
import './contact-map'
import './tab'
import Modal from './modal-service'

new Modal()
