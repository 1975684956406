const $body = $('body')
const $toggleText = $('.js-searchToggleText')
const $searchSubmit = $('.js-searchSubmit')

const hideSearch = () => {
  $toggleText.text('Поиск')
  $body.removeClass('is-search-open')
}

$('.js-searchToggle').on('click', (e) => {
  e.stopPropagation()

  $body.toggleClass('is-search-open')

  if ($body.hasClass('is-search-open')) {
    $toggleText.text('Закрыть')
    setTimeout(() => $('.js-searchInput').focus(), 300)
  } else {
    $toggleText.text('Поиск')
  }
})

$('.js-searchInput').on('input change', (e) => {
  const val = $(e.currentTarget).val()

  if (val !== '') {
    $searchSubmit.addClass('is_submit_active')
    $body.addClass('is-search-result-open')
  } else {
    $searchSubmit.removeClass('is_submit_active')
    $body.removeClass('is-search-result-open')
  }
})

$('.aside').on('click', hideSearch)
$('.search-panel').on('click', hideSearch)
$('.search-panel__container').on('click', (e) => e.stopPropagation())
